import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getPortalImage, resetPassword } from 'api/services/login.services';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import { ReactComponent as LoginImage } from 'assets/images/login.svg';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes'

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [verification, setVerification] = useState<string>('');

    const { data } = useQuery<string>('portal-image', getPortalImage)

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handleVerificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVerification(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const resetMutation = useMutation(resetPassword,
        {
            onSuccess() {
                navigate(`/${ROUTES.LOGIN}`)
            },
        })

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        resetMutation.mutate({ username, newPassword: password, confirmationCode: verification })
    }

    return (
        <>
            <Box sx={{ minHeight: '100vh', display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gridTemplateRows: { xs: '120px 1fr', md: '1fr' } }}>
                <Stack sx={{ bgcolor: (theme) => alpha(theme.palette.info.main, 0.38), position: 'relative', overflow: 'hidden' }} alignItems="center" justifyContent="center">
                    <Box
                        component="img"
                        sx={{
                            height: { xs: 73, md: 97 },
                            width: { xs: 175, md: 388 },
                            objectFit: 'cover'
                        }}
                        src={data}
                    />
                    <Box sx={{
                        position: 'absolute',
                        left: { xs: 'auto', md: '50%' },
                        transform: { xs: 'scaleY(-1)', md: 'translateX(-50%)' },
                        width: { xs: '48%', md: '90%' },
                        bottom: { xs: 'auto', md: '-4px' },
                        right: { xs: '6px', md: 'auto' },
                        top: { xs: '-4px', md: 'auto' }
                    }}
                    >
                        <LoginImage width="100%" />
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        left: { xs: '6px', md: '50%' },
                        transform: { xs: 'scaleY(-1)', md: 'translateX(-50%)scaleY(-1)' },
                        width: { xs: '48%', md: '90%' },
                        top: '-4px'
                    }}
                    >
                        <LoginImage width="100%" />
                    </Box>
                </Stack>
                <Stack alignItems="center" justifyContent={{ xs: 'normal', md: 'center' }} px={{ xs: 2, md: "15%" }} pt={{ xs: 3, md: 0 }} pb={{ xs: 8, md: 0 }} component="form" onSubmit={onSubmit}>
                    <Typography variant="h3" mb={4}>
                        Reset Password
                    </Typography>
                    <Divider flexItem sx={{ mb: 2, display: { xs: 'none', md: 'block' } }} />
                    <InputLabel sx={{ color: "common.black", alignSelf: "start", mb: 1.75, mt: { xs: 'auto', md: 0 } }} htmlFor="username">
                        User name
                    </InputLabel>
                    <TextField variant="outlined" fullWidth placeholder='Enter user name' sx={{ mb: 3 }} id="username"
                        onChange={handleUsernameChange} value={username} required autoComplete='username'
                    />
                    <InputLabel sx={{ color: "common.black", alignSelf: "start", mb: 1.75 }} htmlFor="password">
                        New Password
                    </InputLabel>
                    <TextField type="password" variant="outlined" fullWidth placeholder='Enter password' sx={{ mb: 2 }} id="password"
                        onChange={handlePasswordChange} value={password} required autoComplete='current-password'
                    />
                    <InputLabel sx={{ color: "common.black", alignSelf: "start", mb: 1.75 }} htmlFor="verification">
                        Verification Code
                    </InputLabel>
                    <TextField variant="outlined" fullWidth placeholder='Enter Verification Code' sx={{ mb: 2 }} id="verification"
                        onChange={handleVerificationChange} value={verification} required autoComplete='current-password'
                    />
                    <Button variant="contained" type='submit' size="large" sx={{ width: "200px", height: "52px", borderRadius: "50px", mb: 2 }}>
                        Reset
                    </Button>
                    <Button variant="contained" type='button' size="large" color="secondary" disableElevation
                        component={RouterLink} to={ROUTES.LOGIN}
                        sx={{ width: '200px', height: "52px", borderRadius: "50px", mb: 3, bgcolor: 'grey.200', color: 'grey.700', "&:hover": { bgcolor: "grey.300" } }}>
                        Cancel
                    </Button>
                </Stack>
            </Box >
        </>
    )
}


export default ForgotPassword;