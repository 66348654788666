export enum statusEnum {
    'Pending' = 1,
    'Approved' = 2,
    'Rejected' = 3,
    'Voided' = 4,
    'Closed' = 5,
}

export enum returnEnum {
    Replace = 1,
    Refund = 2,
    Return = 3
}

export enum reasonEnum {
    'Wrong Item' = 1,
    'Damaged Item' = 2,
    'Other' = 3
}