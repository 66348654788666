import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Category } from 'interfaces/products.model'

export interface CategoriesState {
    categories: Category[]
}

const initialState: CategoriesState = {
    categories: []
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<Category[]>) => {
            state.categories = action.payload
        },
    },
})

export const { setCategories } = categoriesSlice.actions

export default categoriesSlice.reducer