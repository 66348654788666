import Header from "components/header/header.component";
import Footer from "components/footer/footer.component";
import { Outlet } from "react-router-dom";
import { getPortalImage } from "api/services/login.services";
import { useQuery } from "react-query";

const Layout = () => {

    const { data } = useQuery<string>('portal-image', getPortalImage)

    return (
        <>
            <Header image={data ?? ''} />
            <main>
                <Outlet />
            </main>
            <Footer image={data ?? ''} />
        </>
    )
}

export default Layout;