export const HOME = '/';
export const LOGIN = 'login'
export const FORGOTPASSOWRD = 'forgot-password'

//ORDERS
export const MYORDERS = 'my-orders'
export const ORDERS = 'orders'
export const ORDERDETAILS = 'orders/:id'
export const PAYMENTHISTORY = 'payment-history'
export const CREDITMEMO = 'credit-memo'
export const CREDITMEMODETAILS = 'credit-memo/:id'

//PRODUCTS
export const PRODUCTS = 'products';

//CART
export const MYCART = 'my-cart'

//UPDATES
export const UPDATES = 'updates'

//RMA
export const RMA = 'rma'
export const RMAREQUEST = 'request'
export const RMADETAILS = ':id'

//CONTACT
export const CONTACT = 'contact'