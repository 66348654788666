import Drawer from "@mui/material/Drawer";
import Typography from '@mui/material/Typography';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCategories } from "api/services/products.services";
import { useQuery } from "react-query";

type props = {
    setSelectedCategory: (category: number | false) => void,
    open: boolean,
    setOpen: (value: boolean) => void
}

interface RenderTree {
    value: number;
    label: string;
    children?: readonly RenderTree[];
}

const SideFilter = (props: props) => {

    const theme = useTheme();
    const isMdOrLess =
        useMediaQuery(theme.breakpoints.down('md'));

    const { setSelectedCategory, open, setOpen } = props

    const { data } = useQuery('item-categories', getCategories)

    const renderTree = (nodes: RenderTree) => (
        <TreeItem key={nodes.value} nodeId={nodes.value?.toString()} label={nodes.label}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
        setSelectedCategory(Number(nodeId));
    };

    return (
        <Drawer
            sx={{
                '& .MuiDrawer-paper': {
                    position: 'static',
                    height: 'auto',
                    m: 4,
                    pt: 4,
                    pb: 20,
                    borderRight: 'none'
                }
            }}
            variant={isMdOrLess ? 'temporary' : 'permanent'}
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Typography variant="subtitle2" px={2} mb={3}>All Categories</Typography>
            {data?.map((category: RenderTree) =>
                <TreeView
                    sx={{
                        flexGrow: 1,
                        maxWidth: '100%',
                        overflowX: 'hidden',
                        '& .MuiTreeItem-content.Mui-selected': {
                            background: 'transparent'
                        }
                    }}
                    defaultCollapseIcon={<ChevronIcon />}
                    defaultExpandIcon={<ChevronIcon style={{ transform: "rotate(-90deg)" }} />}
                    onNodeSelect={handleSelect}
                    key={category.value}
                >
                    {renderTree(category ?? [])}
                </TreeView>
            )}
        </Drawer>
    )
}

export default SideFilter;