export enum statusEnum {
    'All Records' = 0,
    'Opened' = 1,
    'Fulfilled' = 2,
    'Partially Fulfilled' = 3,
    'Shipped' = 4,
    'Partially Shippped' = 5,
    'Voided' = 6,
    'Invoiced' = 7,
    'Estimate' = 8,
    'Estimate Replied' = 9
}

export enum invoiceState {
    'Issued' = 1,
    'Posted' = 2
}