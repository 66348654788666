import { Banner, StandOut } from "interfaces/landingPage.model";
import api from "../api";
export const getBanners = async (count: Number) => {
    const response = await api.get<Banner[]>('top-banners', { params: { count } });
    return response.data;
  };
export const getStandOuts = async (count: Number) => {
    const response = await api.get<StandOut[]>('top-standouts', { params: { count } });
    return response.data;
  };
  

