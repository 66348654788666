import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { LoginService, forgotPassword } from "api/services/login.services";
import { ReactComponent as LoginImage } from "assets/images/login.svg";
import * as ROUTES from "constants/routes";
import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { login } from "store/slices/authSlice";
import logo from "../../assets/images/logo.png";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const forgotEmail = useRef<HTMLInputElement>(null);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEmailError(false);
    setOpenDialog(false);
  };

  const handleSendEmail = () => {
    setEmailError(false);
    if (validateEmail(forgotEmail.current?.value!!)) {
      passwordMutation.mutate(forgotEmail.current?.value!!);
    } else setEmailError(true);
  };

  const validateEmail = (inputText: string) => {
    const mailformat =
      /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };

  const mutation = useMutation(() => LoginService(username, password), {
    onSuccess(response) {
      const { accessToken, idToken, refreshToken } = response;
      localStorage.setItem(
        "ecommerceTokens",
        JSON.stringify({
          accessToken,
          idToken,
          refreshToken,
          creationDate: new Date(),
        })
      );
      dispatch(login());
    },
    onError() {
      setIsError(true);
      setOpenNotification(true);
    },
  });

  const passwordMutation = useMutation(forgotPassword, {
    onSuccess() {
      navigate(`/${ROUTES.FORGOTPASSOWRD}`);
    },
  });

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsError(false);
    mutation.mutate();
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gridTemplateRows: { xs: "120px 1fr", md: "1fr" },
        }}
      >
        <Stack
          sx={{
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.38),
            position: "relative",
            overflow: "hidden",
          }}
          alignItems="center"
          justifyContent="center"
        >
          {/* <StyledImage src={logo} alt='' /> */}
          <Box
            component="img"
            sx={{
              height: { xs: 73, md: 97 },
              width: { xs: 175, md: 388 },
              objectFit: { xs: "contain", md: "cover" },
            }}
            src={logo}
          />
          <Box
            sx={{
              position: "absolute",
              left: { xs: "auto", md: "50%" },
              transform: { xs: "scaleY(-1)", md: "translateX(-50%)" },
              width: { xs: "48%", md: "90%" },
              bottom: { xs: "auto", md: "-4px" },
              right: { xs: "6px", md: "auto" },
              top: { xs: "-4px", md: "auto" },
            }}
          >
            <LoginImage width="100%" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: { xs: "6px", md: "50%" },
              transform: { xs: "scaleY(-1)", md: "translateX(-50%)scaleY(-1)" },
              width: { xs: "48%", md: "90%" },
              top: "-4px",
            }}
          >
            <LoginImage width="100%" />
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent={{ xs: "normal", md: "center" }}
          px={{ xs: 2, md: "15%" }}
          pt={{ xs: 3, md: 0 }}
          pb={{ xs: 8, md: 0 }}
          component="form"
          onSubmit={onSubmit}
        >
          <Typography variant="h3" mb={4}>
            Log in to start
          </Typography>
          <Divider
            flexItem
            sx={{ mb: 2, display: { xs: "none", md: "block" } }}
          />
          <InputLabel
            sx={{
              color: "common.black",
              alignSelf: "start",
              mb: 1.75,
              mt: { xs: "auto", md: 0 },
            }}
            htmlFor="username"
          >
            User name
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter user name"
            sx={{ mb: 3 }}
            id="username"
            onChange={handleUsernameChange}
            value={username}
            required
            autoComplete="username"
            error={isError}
          />
          <InputLabel
            sx={{ color: "common.black", alignSelf: "start", mb: 1.75 }}
            htmlFor="password"
          >
            Password
          </InputLabel>
          <TextField
            type="password"
            variant="outlined"
            fullWidth
            placeholder="Enter password"
            sx={{ mb: 2 }}
            id="password"
            onChange={handlePasswordChange}
            value={password}
            required
            autoComplete="current-password"
            error={isError}
          />
          <Typography variant="body2" sx={{ alignSelf: "flex-start" }} mb={3}>
            By logging in, you agree to our{" "}
            <Link href="#" underline="hover" color="info.main">
              Conditions of Use
            </Link>{" "}
            &{" "}
            <Link href="#" underline="hover" color="info.main">
              Privacy Notice
            </Link>
            .
          </Typography>
          <Button
            variant="contained"
            type="submit"
            size="large"
            sx={{ width: "200px", height: "52px", borderRadius: "50px", mb: 2 }}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            type="button"
            size="large"
            color="secondary"
            disableElevation
            component={RouterLink}
            to={ROUTES.HOME}
            sx={{
              width: "200px",
              height: "52px",
              borderRadius: "50px",
              mb: 3,
              bgcolor: "grey.200",
              color: "grey.700",
              "&:hover": { bgcolor: "grey.300" },
            }}
          >
            Cancel
          </Button>
          <Button variant="text" onClick={handleOpenDialog}>
            Forgot Password?
          </Button>
          <Typography variant="subtitle2">
            Don’t have an account?{" "}
            <Link
              component={RouterLink}
              to={`/${ROUTES.CONTACT}`}
              underline="hover"
              color="primary.main"
            >
              Contact us
            </Link>
          </Typography>
        </Stack>
      </Box>
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Login Failed
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address here. You will receive a
            verification code.
          </DialogContentText>
          <TextField
            autoFocus
            error={emailError}
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            inputRef={forgotEmail}
            helperText={emailError ? "Invalid email" : ""}
            sx={{ mt: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSendEmail}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
