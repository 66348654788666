import { SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Banner } from "interfaces/landingPage.model";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { StyledSwiper } from "./swiper.style";
import { useQuery } from "react-query";
import { getBanners } from "api/services/LandingPage.services";

const SwiperComponent = () => {
  const { data: bannersData } = useQuery("banners", () => getBanners(4));

  return (
    <Box sx={{ height: { xs: "440px", lg: "620px" } }}>
      <StyledSwiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {bannersData &&
          bannersData &&
          bannersData.map((banner: Banner) => (
            <SwiperSlide key={banner.id}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  height: "100%",
                  placeItems: "center",
                  backgroundImage: `url(${banner.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <Stack sx={{ order: { xs: 1, md: 0 }, px: 2 }}>
                  <Typography
                    variant="h4"
                    component="h1"
                    textAlign="center"
                    maxWidth={550}
                    mb={2}
                  >
                    {banner.title}
                  </Typography>
                  <Typography variant="subtitle1" textAlign="center" mb={3}>
                    {banner.subTitle}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      m: "auto",
                      px: 8,
                      py: 2,
                      borderRadius: "50px",
                      zIndex: 1,
                    }}
                    href={banner.actionButtonUrl}
                  >
                    {banner.actionButtonLabel}
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: { xs: "75%", md: "90%" },
                    overflow: "hidden",
                    mt: { xs: 3, md: 0 },
                  }}
                ></Stack>
                <div className="container">
                  <img
                    src={banner.imageUrl}
                    alt={banner.title}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      zIndex: -1,
                    }}
                  />
                </div>
              </Box>
            </SwiperSlide>
          ))}
      </StyledSwiper>
    </Box>
  );
};

export default SwiperComponent;
